import { Empty, Row, Spin } from 'antd';

export const Emptyy = (props: any) => {
	return (
		<Row justify="center" style={{ margin: '40px 0px' }}>
			<Empty description="No Data" />
		</Row>
	);
};

export default Emptyy;
