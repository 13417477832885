import { Col, Row } from 'antd';
import React from 'react';
import EnquiriesList from './EnquiriesList';

export const Enquiries = (props: any) => {
	return (
		<Row gutter={[ 30, 30 ]} style={{ marginTop: 0 }} justify="center">
			<Col lg={22}>
				<EnquiriesList />
			</Col>
		</Row>
	);
};

export default Enquiries;
