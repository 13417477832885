import { Spin } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React from 'react';
import './Content.scss';
interface ContentProps {
	shadow?: boolean;
	props?: any;
	style?: any;
	children?: any;
	loading?: boolean;
	title?: string;
}

export const MyContent = ({ shadow, title, loading, style, children, ...props }: ContentProps) => {
	const shadoStyles = {
		boxShadow: 'rgb(0 0 0 / 3%) 0px 0px 5px 5px',
		borderRadius: 5,
		overflow: 'hidden',
		position: 'relative',
		...style
	};

	return (
		<Content className="site-content" style={shadow ? shadoStyles : style}>
			{loading && (
				<div className="overflow-loading">
					<Spin tip={title} />
				</div>
			)}
			<Content className="site-content-background" {...props}>
				{children}
			</Content>
		</Content>
	);
};

export default MyContent;
