import { Reducer } from "react";
import {
  FETCH_USER_LIST,
  FETCH_USER_LIST_FAILURE,
  FETCH_USER_LIST_SUCCESS,
} from "Redux/Constants";

const INIT_STATE = {
  loading: false,
  users: [],
  error: "",
};

export const UserReducers: Reducer<any, any> = (
  state = INIT_STATE,
  action: any
) => {
  switch (action.type) {
    case FETCH_USER_LIST:
      return { ...state, loading: true };
    case FETCH_USER_LIST_SUCCESS:
      return { ...state, loading: false, ...action.payload };
    case FETCH_USER_LIST_FAILURE:
      return { ...state, loading: false, error: action.payload.error };
    default:
      return { ...state };
  }
};

export default UserReducers;
