import { Descriptions, Divider, Modal, Typography } from "antd"
import dayjs from "dayjs";
import React from "react";
import { defaultFunction } from "Types/Types";
import { EnquiryView } from "./EnquiryView";
import { OrderDetails } from "./OrderView";
import { ReservationView } from "./ReservationView";

interface DetailsViewProps {
    data: any;
    labels?: string[];
    onHide: (e: any) => void;
    show?: any
    module?: any;
}

const DateKeys = ['dob', 'sessionDate'];
const userKeys = ['coach', 'assistantCoach']

export const DetailsView = (params: DetailsViewProps) => {
    const { data, labels, onHide = defaultFunction(''), module } = params;
    return (
        <Modal footer={false} visible={data} width={module === 'order' ? '50vw' : '50vw'} destroyOnClose onCancel={params.onHide}>
            <Typography.Title level={3} style={{ textTransform: 'uppercase' }}>{params?.module} DETAILS</Typography.Title>
            <Divider />
            {params?.module === 'order' && <OrderDetails data={data}/>}
            {params?.module === 'reservation' && <ReservationView data={data}/>}
            {params?.module === 'enquiries' && <EnquiryView data={data}/>}
            {labels?.map((label: any) => {
                return (
                    <>
                    <Descriptions column={2} labelStyle={{ textTransform: 'capitalize' }} contentStyle={{ fontWeight: 'bold' }} title={<><Typography.Title level={5}>{`${label?.title?.toUpperCase()}`}</Typography.Title></>}>
                    {label && label?.keys?.map((key: any) => {
                        return (
                            label?.keys?.includes(key) ? <Descriptions.Item label={key}>
                                {DateKeys.includes(key) ? (
                                    dayjs(data[key]).format('LL')
                                ) : (
                                        <>
                                            {userKeys.includes(key) ? (
                                                data[key]?.name
                                            ): (
                                                data[key]
                                        )}
                                        </>
                                ) }
                                
                            </Descriptions.Item> : false
                        )
                    })}
                        </Descriptions>
                        <Divider/>
                    </>
                )
            })}
        </Modal>
    )
}

export default DetailsView;