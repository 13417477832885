import { Col, Descriptions, Divider, Row, Tag, Typography } from 'antd';
import DataTable from 'Components/Table/DataTable';
import dayjs from 'dayjs';
import React from 'react';
import { TableColumn } from 'react-data-table-component';
import { TotalAmount } from 'Utils/TotalAmount';
import { ReactComponent as TableIcon } from 'Assets/table.svg';

export const ReservationView = (props: any) => {
    const { data } = props;
    TotalAmount(data)
    const OrderColumn: TableColumn[] = [
        {
            name: 'Item Name',
            selector: 'item',
            cell: (row: any) => <Typography.Link>{row?.item?.name}</Typography.Link>

        },
        {
            name: 'Quantity',
            selector: 'quantity'
        },
        {
            name: 'Rate',
            selector: 'tAmount'
        },
        {
            name: 'Total',
            cell: (row: any) => <Typography.Text>{row?.total || (row?.quantity * row?.tAmount)}</Typography.Text>
        }
    ]

    const conditionalRowStyles = [
        {
            when: (row: any) => row?.total > 0,
            style: {
                backgroundColor: 'rgba(63, 195, 128, 0.9)',
                color: 'white',
                '&:hover': {
                    cursor: 'pointer',
                }
            },
        }
    ];
    return (
        <div>
            <Typography.Title disabled level={5}>RESERVATION DETAILS</Typography.Title>
            <Divider />
            <Descriptions column={2} labelStyle={{ color: 'gray' }} contentStyle={{ fontWeight: 'bold', borderBottom: '1px solid #efefef', paddingBottom: 10 }} colon={false} layout="vertical" title="GENERAL DETAILS">
                <Descriptions.Item label="Name">{data?.fullName}</Descriptions.Item>
                <Descriptions.Item label="Booking For">{dayjs(data?.reservationDate).format('LL')}</Descriptions.Item>
                <Descriptions.Item label="Time"><Tag color='gray'>{dayjs(data?.startTime, 'HH:mm:ss').format('LT')} - {dayjs(data?.endTime, 'HH:mm:ss').format('LT')}</Tag></Descriptions.Item>
                <Descriptions.Item label="Phone">{data?.contact}</Descriptions.Item>
                <Descriptions.Item label="Email">{data?.email}</Descriptions.Item>
                {/* <Descriptions.Item label="State">{data?.state}</Descriptions.Item> */}
                <Descriptions.Item label="Zip Code">{data?.zipCode}</Descriptions.Item>
            </Descriptions>
            <Typography.Title disabled level={5}>TABLE DETAILS</Typography.Title>
            <Divider />

            <Row>
                {data?.tables?.map((table: any) => {
                return (
                    <Col lg={8} style={{textAlign: 'center' }}>
            <TableIcon width="80%"/>
                        <Typography.Title style={{ textAlign: 'center' }} level={3}>{table?.name}</Typography.Title>
                        <Typography.Text>Capacity: { table?.capacity}</Typography.Text>
                    </Col>
                )
            })}
            </Row>

            {/* <DataTable conditionalRowStyles={conditionalRowStyles} noHeader title={false} data={[...data?.items]} columns={OrderColumn} /> */}
        </div>
    );
};
