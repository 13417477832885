import { Route, Switch } from 'react-router';
import Orders from 'Views/Order/Orders';
export const OrderRoute = (props: any) => {
  // const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(fetchMembers({ _limit: 10 }))
  // }, [])
  return (
    <Switch>
      <Route path={`${props.match?.path}/`} component={Orders} />
      {/* <Route component={Pages}/> */}
    </Switch>
  )
}

export default OrderRoute;