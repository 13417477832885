import React from 'react';
import { Row, Col, Card, Typography } from 'antd';
import { RouteChildrenProps } from 'react-router-dom';
import { Content } from 'antd/lib/layout/layout';
import MyContent from 'Components/Content/Content';

const Dashboard = (props: RouteChildrenProps) => {
	return (
		<Content>
			<Row>
				<Col lg={12}>
					<MyContent>
						<Typography.Title level={3}>Demo 2 card</Typography.Title>

						<Card>Demo 2 Card</Card>
					</MyContent>
				</Col>
				<Col lg={12}>
					<MyContent>
						<Typography.Title level={3}>Demo Card</Typography.Title>

						<Card>Demo card</Card>
					</MyContent>
				</Col>
			</Row>
		</Content>
	);
};

export default Dashboard;
