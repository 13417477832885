import { notification } from 'antd';
import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
	createOrderFailure,
	createOrderSuccess,
	deleteOrderFailure,
	deleteOrderSuccess,
	fetchOrderFailure,
	fetchOrdersFailure,
	fetchOrdersSuccess,
	fetchOrderSuccess,
	updateOrderFailure,
	updateOrderSuccess
} from './OrderActions';
import { cutOrder, makeOrder, modifyOrder, takeOrder, takeOrders } from './OrderApis';
import {
	CreateOrderParams,
	FETCH_ORDERS,
	UPDATE_ORDER,
	OrderDetailsParams,
	OrderFetchParams,
	FETCH_ORDER_DETAILS,
	CREATE_ORDER,
	REMOVE_ORDER
} from './OrderTypes';

//get orders
const getOrdersAsync = async (data: OrderFetchParams) => await takeOrders(data);

function* getOrders(params: any) {
	const { payload } = params;
	try {
		const orders: AxiosResponse = yield call(getOrdersAsync, payload);
		if (orders) {
			yield put(fetchOrdersSuccess(orders.data));
		} else {
			yield put(fetchOrdersFailure('Could not get list of orders'));
			notification.error({
				message: 'Get Order List',
				description: 'Could not get list of orders'
			});
		}
	} catch (error) {
		yield put(fetchOrdersFailure('Network Error while getting Orders'));
		notification.error({
			message: 'Get Order List',
			description: 'Network Error while getting Orders'
		});
	}
}

function* watchGetOrders() {
	yield takeLatest(FETCH_ORDERS, getOrders);
}

//get order details
const getOrderAsync = async (data: OrderDetailsParams) => await takeOrder(data);

function* getOrder(params: any) {
	const { payload } = params;
	try {
		const orders: AxiosResponse = yield call(getOrderAsync, payload);
		if (orders) {
			yield put(fetchOrderSuccess(orders.data));
		} else {
			yield put(fetchOrderFailure('Could not get list of orders'));
			notification.error({
				message: 'Get Order',
				description: 'Could not get list of orders'
			});
		}
	} catch (error) {
		yield put(fetchOrderFailure('Network Error while getting Orders'));
		notification.error({
			message: 'Get Order',
			description: 'Network Error while getting Orders'
		});
	}
}

function* watchGetOrder() {
	yield takeLatest(FETCH_ORDER_DETAILS, getOrder);
}

//create Order
const generateOrderAsync = async (data: CreateOrderParams) => await makeOrder(data);

function* generateOrder(params: any) {
	const { payload } = params;
	try {
		const orders = yield call(generateOrderAsync, payload);
		if (orders) {
			yield put(createOrderSuccess(orders.data));
			notification.success({
				message: 'Create Order',
				description: 'Order created successfully'
			});
		} else {
			const message = 'Could not create Order';
			yield put(createOrderFailure(message));
			notification.error({
				message: 'Create Order',
				description: message
			});
		}
	} catch (error) {
		const message = 'Network Error while creating Order';
		yield put(createOrderFailure(message));
		notification.error({
			message: 'Create Order',
			description: message
		});
	}
}

function* watchGenerateOrder() {
	yield takeLatest(CREATE_ORDER, generateOrder);
}

//update Order
const amendOrderAsync = async (data: CreateOrderParams) => await modifyOrder(data);

function* amendOrder(params: any) {
	const { payload } = params;
	try {
		const orders = yield call(amendOrderAsync, payload);
		if (orders) {
			yield put(updateOrderSuccess(orders.data));
			notification.success({
				message: 'Update Order',
				description: 'Order updated successfully'
			});
		} else {
			const message = 'Could not Update Order';
			yield put(updateOrderFailure(message));
			notification.error({
				message: 'Update Order',
				description: message
			});
		}
	} catch (error) {
		const message = 'Network Error while updating Order';
		yield put(updateOrderFailure(message));
		notification.error({
			message: 'Update Order',
			description: message
		});
	}
}

function* watchAmendOrder() {
	yield takeLatest(UPDATE_ORDER, amendOrder);
}

//Delete Order
const removeOrderAsync = async (data: OrderDetailsParams) => await cutOrder(data);

function* removeOrder(params: any) {
	const { payload } = params;
	try {
		const orders = yield call(removeOrderAsync, payload);
		if (orders) {
			yield put(deleteOrderSuccess(orders.data));
			notification.success({
				message: 'Order Deleted',
				description: 'Order deleted successfully'
			});
		} else {
			const message = 'Could not delete Order';
			yield put(deleteOrderFailure(message));
			notification.error({
				message: 'Order Deleted',
				description: message
			});
		}
	} catch (error) {
		const message = 'Network Error while deleting Order';
		yield put(deleteOrderFailure(message));
		notification.error({
			message: 'Order Deleted',
			description: message
		});
	}
}

function* watchRemoveOrder() {
	yield takeLatest(REMOVE_ORDER, removeOrder);
}

export default function* OrderSagas() {
	yield all([
		fork(watchGetOrders),
		fork(watchGetOrder),
		fork(watchGenerateOrder),
		fork(watchAmendOrder),
		fork(watchRemoveOrder)
	]);
}
