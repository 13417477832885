export const status = [
	{
		key: 'isConfirmed',
		name: 'Confirmed',
		id: 1
	},
	{
		key: 'isRejected',
		name: 'Rejected',
		id: 2
	},
	{
		key: 'isDispatched',
		name: 'Dispatched',
		id: 3
	}
];

export const getStatusKey = (order: any) => {
    if (order?.isCompleted) {
        
        return 5;
    }
    if (order?.isDispatched) {
        return 3;
    }
    else if (order?.isRejected) {
        return 2;
    }
    else if (order?.isConfirmed) {
        return 1;
    }
    else if (order?.isCompleted) {
        return 5;
        }
    else {
        return 4
    }
}


export const getReservationStatus = (order: any) => {
    if (order?.isCompleted) {
        return 15;
    }
    if (order?.isConfirmed) {
        return 11;
    }

    else if (order?.isRejected) {
        return 12;
    }
        
    else {
        return 14
    }
}

