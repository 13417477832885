import {
	BellTwoTone,
	DollarCircleTwoTone,
	InboxOutlined,
	LogoutOutlined,
	ProjectTwoTone,
	RightOutlined,
	SettingOutlined,
	SmileTwoTone,
	UserOutlined
} from '@ant-design/icons';
import { Button, Card, Dropdown, List, Space, Typography } from 'antd';
import React, { useContext } from 'react';
import './MyHeader.scss';
import { ThemeContext } from 'styled-components';
import { useHistory } from 'react-router';
const ProfileHeader = () => {
	const theme = useContext(ThemeContext);
	console.log(theme);
	return (
		<div
			className="profile-header"
			style={{
				background: theme.primary,
				display: 'flex',
				alignItems: 'center'
			}}
		>
			<Space style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
				<UserOutlined style={{ fontSize: 24, color: 'white' }} />
				<Typography.Title level={4} style={{ color: 'white', marginBottom: 0 }}>
					John Smith
				</Typography.Title>
			</Space>
			<SettingOutlined key="setting" style={{ color: 'white', fontSize: 20 }} />
		</div>
	);
};

const data = [
	{
		title: 'My Profile',
		avatar: <SmileTwoTone style={{ fontSize: 24 }} />,
		desc: 'Personal Information'
	},
	{
		title: 'My Inbox',
		avatar: <InboxOutlined style={{ fontSize: 24 }} />,
		desc: 'Messages & Latest News'
	},
	{
		title: 'My Projects',
		avatar: <ProjectTwoTone style={{ fontSize: 24 }} />,
		desc: 'Tasks & Active Projects'
	},
	{
		title: 'Billing Information',
		avatar: <DollarCircleTwoTone style={{ fontSize: 24 }} />,
		desc: 'Pricing & Current Plan'
	}
];

const ProfileMenu = () => {
	return (
		<div>
			<Card
				style={{ width: 300 }}
				cover={<ProfileHeader />}
				actions={[
					<SettingOutlined key="setting" />,
					// <EditOutlined key="edit" />,
					<Button
						type="primary"
						onClick={() => {
							localStorage.removeItem('auths');
							localStorage.removeItem('loggedIn');
							window.location.reload();
						}}
						icon={<LogoutOutlined />}
						danger
					>
						Logout
					</Button>
				]}
			>
				{/* <List
					itemLayout="horizontal"
					dataSource={data}
					renderItem={(item) => (
						<List.Item>
							<List.Item.Meta
								avatar={item.avatar}
								title={<a href="https://ant.design">{item.title}</a>}
								description={item.desc}
							/>
							<RightOutlined style={{ fontSize: 12 }} />
						</List.Item>
					)}
				/> */}
			</Card>
		</div>
	);
};

export const MyHeader = (props: any) => {
	return (
		<div
			className="my-header"
			style={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				paddingRight: 20
			}}
		>
			{/* <Dropdown overlay={ProfileMenu} trigger={["click"]}>
        <BellTwoTone twoToneColor="#eb2f96" style={{ fontSize: 26 }} />
      </Dropdown> */}
			<Dropdown overlay={ProfileMenu} trigger={[ 'click' ]} className="my-profile">
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						marginLeft: 20
					}}
				>
					<span>John Smith</span>
					<UserOutlined
						style={{
							fontSize: 26,
							marginLeft: 10
						}}
					/>
				</div>
			</Dropdown>
		</div>
	);
};

export default MyHeader;
