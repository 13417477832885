import { CloudUploadOutlined, EllipsisOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Input, Row, Typography } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { useDispatch } from 'react-redux';
import { setLayout } from 'Redux/Actions/LayoutActions';
import { defaultFunction } from 'Redux/Constants';

export interface TableHeaderProps {
	title: string;
	module?: string;
	onFilter?: (e: any) => void;
	onClear?: () => void;
	noAddFeature?: boolean;
	filterText?: string;
	extraComponent?: any;
	otherModule?: any;
	extraRight?: any;
	half?: boolean;
}

export const TableHeader: React.FC<TableHeaderProps> = ({
	title,
	noAddFeature,
	module,
	onFilter = defaultFunction,
	filterText,
	extraComponent,
	otherModule,
	extraRight,
	half
}) => {
	const dispatch = useDispatch();
	return (
		<Content>
			<Row justify="space-between" align="middle">
				<Col flex="auto">
					<Row>
						<Col>
							<Typography.Title style={{ textTransform: 'uppercase', marginBottom: 0 }} level={4}>
								{title}
							</Typography.Title>
						</Col>
						<Col flex="auto" style={{ display: 'flex', justifyContent: 'center' }}>
							{extraComponent}
						</Col>
					</Row>
				</Col>
				<div>
					{extraRight}
					{otherModule?.map((d: any) => {
						return (
							<Button
								key="4"
								type="primary"
								icon={<PlusOutlined />}
								size="middle"
								style={{ marginRight: 20 }}
								onClick={() => dispatch(setLayout({ form: d.module }))}
							>
								ADD {d?.title?.toUpperCase()}
							</Button>
						)
					})}
					{!noAddFeature && (
						<Button
							type="primary"
							icon={<PlusOutlined />}
							size="middle"
							onClick={() => dispatch(setLayout({ form: module }))}
						>
							ADD {module?.toUpperCase()}
						</Button>
					)}
				</div>
			</Row>
			<Row style={{ marginTop: 20 }} align="middle" justify="space-between">
				<Col lg={half ? 16 : 20}>
					<Input
						size="large"
						placeholder="Search ..."
						prefix={<SearchOutlined />}
						onChange={(e) => onFilter(e)}
						value={filterText}
						allowClear
					/>
				</Col>
				{/* <Col lg={half ? 8 : 4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
					<Button icon={<CloudUploadOutlined />} type="text">
						Export
					</Button>
					<Button icon={<EllipsisOutlined />} type="text">
						More
					</Button>
				</Col> */}
			</Row>
			<Divider />
		</Content>
	);
};
