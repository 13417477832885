import { Tag } from 'antd';
const statusOptions = (id: number) => {
	switch (id) {
		case 1:
			return { name: 'Confirmed', color: 'green' };
		case 2:
			return { name: 'Rejected', color: 'maroon' };
		case 3:
			return { name: 'Dispatched', color: 'orange' };
		case 4:
			return { name: 'Pending', color: 'yellow' };
		case 5:
			return { name: 'COMPLETED', color: 'blue' };

		case 11:
			return { name: 'Confirmed', color: 'green' };
		case 12:
			return { name: 'Rejected', color: 'maroon' };
		case 14:
			return { name: 'Pending', color: 'yellow' };
		case 15:
			return { name: 'COMPLETED', color: 'green' };

		case 10:
			return { name: 'Strict', color: 'maroon' };
		case 11:
			return { name: 'Flexible', color: 'green' };

		case 20:
			return { name: 'Automatic', color: 'blue' };
		case 21:
			return { name: 'Manual', color: 'red' };

		case 50:
			return { name: 'Verified', color: 'green' };
		case 51:
			return { name: 'Not Verified', color: 'maroon' };
		default:
			return { name: 'N/A', color: 'gray' };
	}
};

export const Status = (props: any) => {
	const details = statusOptions(props.id);
	return <Tag color={details.color}>{details.name}</Tag>;
};
export default Status;
