import { Route, RouteChildrenProps, Switch } from "react-router";
import Dashboard from "Views/Dashboard/Dashboard";
export const DashboardRoute = (props: RouteChildrenProps) => {
  return (
    <Switch>
      <Route path={`${props?.match?.path}/`} exact component={Dashboard} />
      <Route component={Dashboard} />
    </Switch>
  );
};

export default DashboardRoute;
