import { EnvironmentOutlined, MobileOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Select,
  Typography,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import { Content } from "antd/lib/layout/layout";
import { Option } from "antd/lib/mentions";

export const UserForm = (props: any) => {
  return (
    <Content>
      <Typography.Title level={4}>Add User</Typography.Title>
      <Form
        // {...layout}
        layout="vertical"
        name="basic"
        initialValues={{ remember: true }}
        // onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
      >
        <Row>
          <Col lg={12}>
            <Form.Item
              label="First Name"
              name="firstName"
              rules={[{ required: true, message: "First Name is required!" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col lg={12}>
            <Form.Item
              label="Last Name"
              name="lastName"
              rules={[{ required: true, message: "Last Name is Required!" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col lg={24}>
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: "Email is Required!" }]}
            >
              <Input type="email" prefix={"@"} />
            </Form.Item>
          </Col>
          <Col lg={12}>
            <Form.Item
              label="Phone"
              name="phone"
              rules={[{ required: true, message: "Phone is Required!" }]}
            >
              <Input type="number" prefix={<MobileOutlined />} />
            </Form.Item>
          </Col>
          <Col lg={12}>
            <Form.Item
              label="Organization"
              name="organization"
              rules={[{ required: true, message: "Organization is Required!" }]}
            >
              <Select allowClear>
                <Option value="1">Org 1</Option>
                <Option value="2">Org 2</Option>
                <Option value="3">Org 3</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col lg={24}>
            <Form.Item
              label="Address"
              name="address"
              rules={[{ required: true, message: "Address is Required!" }]}
            >
              <Input type="number" prefix={<EnvironmentOutlined />} />
            </Form.Item>
          </Col>
          <Col lg={24}>
            <Form.Item
              label="Remarks"
              name="remarks"
              // rules={[{ required: true, message: "Address is Required!" }]}
            >
              <TextArea />
            </Form.Item>
          </Col>

          <Col lg={12}>
            <Form.Item label="" name="forcePassword">
              <Checkbox>Forcefully change password</Checkbox>
            </Form.Item>
          </Col>

          <Col lg={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Content>
  );
};

export default UserForm;
