import {
	BookFilled,
	BookOutlined,
	DashboardFilled,
	GroupOutlined,
	QuestionCircleOutlined,
	SettingOutlined,
	UsergroupAddOutlined
} from '@ant-design/icons';
import { Menu } from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
import { useHistory } from 'react-router';

export const Sidebar = () => {
	const history = useHistory();
	const onMenuClick = (value: any) => {
		history.push('/' + value.keyPath.reverse().join('/'));
	};

	return (
		<Menu theme="light" mode="inline" defaultSelectedKeys={[ 'dashboard' ]} onClick={onMenuClick}>
			<Menu.Item key="dashboard" icon={<DashboardFilled />} title="Dashboard">
				Dashboard
			</Menu.Item>
			{/* <SubMenu key="users" icon={<UsergroupAddOutlined />} title="Users">
				<Menu.Item key="users-list">Users</Menu.Item>
				<Menu.Item key="roles-list">Roles</Menu.Item>
			</SubMenu> */}
			{/* <Menu.Item key="pages" icon={<BookFilled />} title="Pages">
				Pages
			</Menu.Item>
			<Menu.Item key="posts" icon={<GroupOutlined />} title="Posts">
				Posts
			</Menu.Item> */}
			<Menu.Item key="orders" icon={<UsergroupAddOutlined />}>
				Orders
			</Menu.Item>
			<Menu.Item key="reservations" icon={<BookOutlined />}>
				Reservations
			</Menu.Item>
			<Menu.Item key="enquiries" icon={<QuestionCircleOutlined />}>
				Enquiries
			</Menu.Item>
			{/* <Menu.Item key="members" icon={<UsergroupAddOutlined />}>
				Members
			</Menu.Item> */}
			{/* 
			<SubMenu key="settings" icon={<SettingOutlined />} title="Settings">
				<Menu.Item key="post-categories">Post Categories</Menu.Item>
				<Menu.Item key="page-templates">Page Templates</Menu.Item>
			</SubMenu> */}
		</Menu>
	);
};

export default Sidebar;
