import { Col, Row } from 'antd';
import React from 'react';
import OrderList from './OrderList';

export const Orders = (props: any) => {
	return (
		<Row gutter={[ 30, 30 ]} style={{ marginTop: 0 }} justify="center">
			<Col lg={22}>
				<OrderList />
			</Col>
		</Row>
	);
};

export default Orders;
