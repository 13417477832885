import { Modal } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLayout } from 'Redux/Actions/LayoutActions';
import UserForm from './Forms/UserForm';

const SelectForm = (form: string) => {
	switch (form) {
		case 'user':
			return <UserForm />;
	}
};

export const Form = () => {
	const layout = useSelector((state: any) => state.LayoutReducer);
	const dispatch = useDispatch();
	return (
		<Modal
			visible={layout.form}
			onCancel={() => dispatch(setLayout({ data: false, update: false, form: false }))}
			footer={null}
		>
			{SelectForm(layout.form)}
		</Modal>
	);
};

export default Form;
