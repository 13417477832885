import {
	CreateReservationParams,
	CREATE_RESERVATION,
	CREATE_RESERVATION_FAILURE,
	CREATE_RESERVATION_SUCCESS,
	FETCH_RESERVATIONS,
	FETCH_RESERVATIONS_FAILURE,
	FETCH_RESERVATIONS_SUCCESS,
	FETCH_RESERVATION_DETAILS,
	FETCH_RESERVATION_DETAILS_FAILURE,
	FETCH_RESERVATION_DETAILS_SUCCESS,
	REMOVE_RESERVATION,
	REMOVE_RESERVATION_FAILURE,
	REMOVE_RESERVATION_SUCCESS,
	UPDATE_RESERVATION,
	UPDATE_RESERVATION_FAILURE,
	UPDATE_RESERVATION_SUCCESS,
	ReservationDetailsParams,
	ReservationFetchParams
} from './ReservationTypes';

//fetch Reservation List
export const fetchReservations = (params: ReservationFetchParams) => ({
	type: FETCH_RESERVATIONS,
	payload: params
});

export const fetchReservationsSuccess = (response: any) => ({
	type: FETCH_RESERVATIONS_SUCCESS,
	payload: response
});

export const fetchReservationsFailure = (error: any) => ({
	type: FETCH_RESERVATIONS_FAILURE,
	payload: error
});

//fetch Reservation Details
export const fetchReservation = (params: ReservationDetailsParams) => ({
	type: FETCH_RESERVATION_DETAILS,
	payload: params
});

export const fetchReservationSuccess = (response: any) => ({
	type: FETCH_RESERVATION_DETAILS_SUCCESS,
	payload: response
});

export const fetchReservationFailure = (error: any) => ({
	type: FETCH_RESERVATION_DETAILS_FAILURE,
	payload: error
});

//create Reservation
export const createReservation = (params: CreateReservationParams) => ({
	type: CREATE_RESERVATION,
	payload: params
});

export const createReservationSuccess = (response: CreateReservationParams) => ({
	type: CREATE_RESERVATION_SUCCESS,
	payload: response
});
export const createReservationFailure = (error: any) => ({
	type: CREATE_RESERVATION_FAILURE,
	payload: error
});

//update Reservation
export const updateReservation = (params: CreateReservationParams) => ({
	type: UPDATE_RESERVATION,
	payload: params
});

export const updateReservationSuccess = (response: CreateReservationParams) => ({
	type: UPDATE_RESERVATION_SUCCESS,
	payload: response
});

export const updateReservationFailure = (error: any) => ({
	type: UPDATE_RESERVATION_FAILURE,
	payload: error
});

//delete Reservation
export const deleteReservation = (params: ReservationDetailsParams) => ({
	type: REMOVE_RESERVATION,
	payload: params
});
export const deleteReservationSuccess = (response: ReservationDetailsParams) => ({
	type: REMOVE_RESERVATION_SUCCESS,
	payload: response
});
export const deleteReservationFailure = (error: any) => ({
	type: REMOVE_RESERVATION_FAILURE,
	payload: error
});
