import { notification } from 'antd';
import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
	createEnquiryFailure,
	createEnquirySuccess,
	deleteEnquiryFailure,
	deleteEnquirySuccess,
	fetchEnquiryFailure,
	fetchEnquirysFailure,
	fetchEnquirysSuccess,
	fetchEnquirySuccess,
	updateEnquiryFailure,
	updateEnquirySuccess
} from './EnquiryActions';
import { cutEnquiry, makeEnquiry, modifyEnquiry, takeEnquiry, takeEnquirys } from './EnquiryApis';
import { CreateEnquiryParams, CREATE_ENQUIRY,REMOVE_ENQUIRY, FETCH_ENQUIRYS,FETCH_ENQUIRY_DETAILS, UPDATE_ENQUIRY, EnquiryDetailsParams, EnquiryFetchParams } from './EnquiryTypes';

//get enquirys
const getEnquirysAsync = async (data: EnquiryFetchParams) => await takeEnquirys(data);

function* getEnquirys(params: any) {
	const { payload } = params;
	try {
		const enquirys: AxiosResponse = yield call(getEnquirysAsync, payload);
		if (enquirys) {
			yield put(fetchEnquirysSuccess(enquirys.data));
		} else {
			yield put(fetchEnquirysFailure('Could not get list of enquirys'));
			notification.error({
				message: 'Get Enquiry List',
				description: 'Could not get list of enquirys'
			});
		}
	} catch (error) {
		yield put(fetchEnquirysFailure('Network Error while getting Enquirys'));
		notification.error({
			message: 'Get Enquiry List',
			description: 'Network Error while getting Enquirys'
		});
	}
}

function* watchGetEnquirys() {
	yield takeLatest(FETCH_ENQUIRYS, getEnquirys);
}

//get enquiry details
const getEnquiryAsync = async (data: EnquiryDetailsParams) => await takeEnquiry(data);

function* getEnquiry(params: any) {
	const { payload } = params;
	try {
		const enquirys: AxiosResponse = yield call(getEnquiryAsync, payload);
		if (enquirys) {
			yield put(fetchEnquirySuccess(enquirys.data));
		} else {
			yield put(fetchEnquiryFailure('Could not get list of enquirys'));
			notification.error({
				message: 'Get Enquiry',
				description: 'Could not get list of enquirys'
			});
		}
	} catch (error) {
		yield put(fetchEnquiryFailure('Network Error while getting Enquirys'));
		notification.error({
			message: 'Get Enquiry',
			description: 'Network Error while getting Enquirys'
		});
	}
}

function* watchGetEnquiry() {
	yield takeLatest(FETCH_ENQUIRY_DETAILS, getEnquiry);
}

//create Enquiry
const generateEnquiryAsync = async (data: CreateEnquiryParams) => await makeEnquiry(data);

function* generateEnquiry(params: any) {
	const { payload } = params;
	try {
		const enquirys: AxiosResponse = yield call(generateEnquiryAsync, payload);
		if (enquirys) {
			yield put(createEnquirySuccess(enquirys.data));
			notification.success({
				message: 'Create Enquiry',
				description: 'Enquiry created successfully'
			});
		} else {
			const message = 'Could not create Enquiry';
			yield put(createEnquiryFailure(message));
			notification.error({
				message: 'Create Enquiry',
				description: message
			});
		}
	} catch (error) {
		const message = 'Network Error while creating Enquiry';
		yield put(createEnquiryFailure(message));
		notification.error({
			message: 'Create Enquiry',
			description: message
		});
	}
}

function* watchGenerateEnquiry() {
	yield takeLatest(CREATE_ENQUIRY, generateEnquiry);
}

//update Enquiry
const amendEnquiryAsync = async (data: CreateEnquiryParams) => await modifyEnquiry(data);

function* amendEnquiry(params: any) {
	const { payload } = params;
	try {
		const enquirys: AxiosResponse = yield call(amendEnquiryAsync, payload);
		if (enquirys) {
			yield put(updateEnquirySuccess(enquirys.data));
			notification.success({
				message: 'Update Enquiry',
				description: 'Enquiry updated successfully'
			});
		} else {
			const message = 'Could not Update Enquiry';
			yield put(updateEnquiryFailure(message));
			notification.error({
				message: 'Update Enquiry',
				description: message
			});
		}
	} catch (error) {
		const message = 'Network Error while updating Enquiry';
		yield put(updateEnquiryFailure(message));
		notification.error({
			message: 'Update Enquiry',
			description: message
		});
	}
}

function* watchAmendEnquiry() {
	yield takeLatest(UPDATE_ENQUIRY, amendEnquiry);
}

//Delete Enquiry
const removeEnquiryAsync = async (data: EnquiryDetailsParams) => await cutEnquiry(data);

function* removeEnquiry(params: any) {
	const { payload } = params;
	try {
		const enquirys: AxiosResponse = yield call(removeEnquiryAsync, payload);
		if (enquirys) {
			yield put(deleteEnquirySuccess(enquirys.data));
			notification.success({
				message: 'Enquiry Deleted',
				description: 'Enquiry deleted successfully'
			});
		} else {
			const message = 'Could not delete Enquiry';
			yield put(deleteEnquiryFailure(message));
			notification.error({
				message: 'Enquiry Deleted',
				description: message
			});
		}
	} catch (error) {
		const message = 'Network Error while deleting Enquiry';
		yield put(deleteEnquiryFailure(message));
		notification.error({
			message: 'Enquiry Deleted',
			description: message
		});
	}
}

function* watchRemoveEnquiry() {
	yield takeLatest(UPDATE_ENQUIRY, removeEnquiry);
}

export default function* EnquirySagas() {
	yield all([
		fork(watchGetEnquirys),
		fork(watchGetEnquiry),
		fork(watchGenerateEnquiry),
		fork(watchAmendEnquiry),
		fork(watchRemoveEnquiry)
	]);
}
