import API from 'Api/Api';
import { CreateEnquiryParams, EnquiryDetailsParams, EnquiryFetchParams } from './EnquiryTypes';

//get Enquiry
export const takeEnquirys = (data: EnquiryFetchParams) => {
	return API({
		url: '/enquiries',
		method: 'GET',
		params: data
	});
};

//get Enquiry Details
export const takeEnquiry = (data: EnquiryDetailsParams) => {
	return API({
		url: '/enquiries',
		method: 'GET',
		params: data
	});
};

//create Enquiry Details
export const makeEnquiry = (data: CreateEnquiryParams) => {
	return API({
		url: '/enquiries',
		method: 'POST',
		params: data
	});
};

//modify Enquiry
export const modifyEnquiry = (data: CreateEnquiryParams) => {
	return API({
		url: '/enquiries',
		method: 'PUT',
		params: data
	});
};

//delete Enquiry
export const cutEnquiry = (data: EnquiryDetailsParams) => {
	return API({
		url: '/enquiries',
		method: 'DELETE',
		params: data
	});
};
