import DataTable from "Components/Table/DataTable";
import { TableHeader } from "Components/TableHeader/TableHeader";
import { FormEvent, useEffect, useState } from "react";
import { TableColumn } from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { setLayout } from "Redux/Actions/LayoutActions";
import React from "react"
import MyContent from "Components/Content/Content";
import TableActions from "Components/TableAction/TableAction";
import { Button, Modal, Tag, Typography } from "antd";
import dayjs from "dayjs";
import { CloudDownloadOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import Status from "Components/Status/Status";
import { fetchOrders, updateOrder } from "Redux/OrderRedux/OrderActions";
import { getStatusKey } from "Utils/OrderStatuses";
import { NextOrderStep } from "Components/NextStep/NextStep";
import DetailsView from "Components/DetailsView/DetailsView";

export const OrderList = (props: any) => {
	const exams = useSelector((state: any) => state.OrderReducer);
	const [currentExam, setCurrentExam] = useState<any>(false);

const [details, setDetails] = useState<any>(false);

	const [filterText, setFilterText] = React.useState('');
	const [perPageData, setPerPageData] = useState(10);
	const [filteredItems, setFilteredItems] = useState<any>([]);
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
	const [isCSV, setIsCSV] = useState(false);

	const history = useHistory();


	const dispatch = useDispatch();
	const handleClear = () => {
		if (filterText) {
			setResetPaginationToggle(!resetPaginationToggle);
			setFilterText('');
		}
	};

	//Fetch data with filter
	const fetchWithFilter = (data: any) => {
		// console.log(data)
		dispatch(fetchOrders({...data, _sort: 'createdAt:desc'}))
	}
	//Row and Per page change
	const handlePerRowsChange = (perPage: number, page: number) => {
		fetchWithFilter({ page: page, limit: perPage });
		setPerPageData(perPage);
	};

	//on table page change
	const handlePageChange = (page: number) => {
		fetchWithFilter({ page: page, limit: perPageData });
	};

	//on row clicked
	const onRowClicked = (values: any) => {
		// setShowDetails(true);
		setDetails(values)
	}

	const onEditHandler = (data: any) => {
		dispatch(setLayout({ data: data, form: 'exam', update: true }));
	}

	const onDeleteHandler = (id: number) => {
		console.log(id)
		// dispatch(removeEmployee(id))
	}

	const userColumns: TableColumn[] = [
		{
			name: 'Name',
			selector: 'name',
			sortable: true
		},
		{
			name: 'Address',
			selector: 'address',
			cell: (row: any) => <Typography.Text>{ row?.address}<Tag style={{marginLeft: 10}} color="blue">TAKE AWAY</Tag></Typography.Text>
		},
		{
			name: 'Order Time',
			selector: 'createdAt',
			sortable: true,
			width: '200px',
			cell: (row: any) => <Typography.Text style={{ textTransform: 'capitalize' }}>{ dayjs(row?.createdAt).format('LLL')}</Typography.Text>
		},
		// {
		// 	name: 'Email',
		// 	selector: 'email',
		// 	cell: (row: any) => <Typography.Link>{ row?.email}</Typography.Link>

		// },
		{
			name: 'Status',
			cell: (row: any) => <Status id={getStatusKey(row)} />,
			width: '150px'
		},
		{
			name: 'Action',
			// cell: (row: any) => <TableActions onJoin={()=> setCurrentExam(row?.id)} onDetail={()=> history.push(`/exams/details/${row.id}`)} noDeletable onDelete={() => onDeleteHandler(row.id)} onEdit={() => onEditHandler(row)} />
			cell: (row: any) => <NextOrderStep onCompleted={()=> handleCompleted(row?.id)} onDispatched={()=> handleDispatched(row?.id)}  onReject={()=> handleReject(row?.id)} onConfirm={()=> handleConfirm(row?.id)} id={ getStatusKey(row)}/>
		}
	];

	const handleConfirm = (id: any) => {
		dispatch(updateOrder({id: id, isConfirmed: true}))
	}
	const handleReject = (id: any) => {
		dispatch(updateOrder({id: id, isRejected: true}))
	}

	const handleDispatched = (id: any) => {
		dispatch(updateOrder({id: id, isDispatched: true}))
	}

	const handleCompleted = (id: any) => {
		dispatch(updateOrder({id: id, isCompleted: true}))
	}

	useEffect(() => {
		const fItems = exams?.list?.filter(
			(item: any) => item.name && item.name.toLowerCase().includes(filterText.toLowerCase())
		);
		setFilteredItems(fItems);

	}, [filterText, exams])



	useEffect(() => {
		if (!exams.loaded) {
			fetchWithFilter({});
		}
	}, [])

	const ExtraComponent = (props: any) => {
		return (
			<>
				<Button onClick={() => setIsCSV(true)} style={{ marginRight: 15 }} type="primary" danger icon={< CloudDownloadOutlined />}>Import Questions</Button>
			</>
		)
	}


	return (
		<>
			<TableHeader
				onFilter={(e: FormEvent<HTMLInputElement>) => setFilterText(e.currentTarget.value)}
				onClear={handleClear}
				filterText={filterText}
				module="exam"
				title="Orders"
				noAddFeature
				// otherModule={[{ title: "Level", module: "exam-level" }]}
				// extraRight={<ExtraComponent />}
			/>
			<MyContent shadow={true}>
				<DataTable
					columns={userColumns}
					data={filteredItems}
					progressPending={exams.loading}
					noHeader
					pagination
					onRowClicked={onRowClicked}
					paginationServer
					onChangeRowsPerPage={handlePerRowsChange}
					onChangePage={handlePageChange}
					paginationTotalRows={exams.total}
				/>
			</MyContent>

				<DetailsView module="order" data={details} onHide={() => setDetails(false)} />

		</>
	);
};

export default OrderList;
