export const FETCH_ENQUIRYS = 'FETCH_ENQUIRYS';
export const FETCH_ENQUIRYS_SUCCESS = 'FETCH_ENQUIRYS_SUCCESS';
export const FETCH_ENQUIRYS_FAILURE = 'FETCH_ENQUIRYS_FAILURE';

export const FETCH_ENQUIRY_DETAILS = 'FETCH_ENQUIRY_DETAILS';
export const FETCH_ENQUIRY_DETAILS_SUCCESS = 'FETCH_ENQUIRY_DETAILS_SUCCESS';
export const FETCH_ENQUIRY_DETAILS_FAILURE = 'FETCH_ENQUIRY_DETAILS_FAILURE';

export const CREATE_ENQUIRY = 'CREATE_ENQUIRY';
export const CREATE_ENQUIRY_SUCCESS = 'CREATE_ENQUIRY_SUCCESS';
export const CREATE_ENQUIRY_FAILURE = 'CREATE_ENQUIRY_FAILURE';

export const UPDATE_ENQUIRY = 'UPDATE_ENQUIRY';
export const UPDATE_ENQUIRY_SUCCESS = 'UPDATE_ENQUIRY_SUCCESS';
export const UPDATE_ENQUIRY_FAILURE = 'UPDATE_ENQUIRY_FAILURE';

export const REMOVE_ENQUIRY = 'REMOVE_ENQUIRY';
export const REMOVE_ENQUIRY_SUCCESS = 'REMOVE_ENQUIRY_SUCCESS';
export const REMOVE_ENQUIRY_FAILURE = 'REMOVE_ENQUIRY_FAILURE';

export interface EnquiryFetchParams {
	limit?: number;
	page?: number;
}
export interface EnquiryDetailsParams {
	id?: number;
}
export interface CreateEnquiryParams {
	id?: number;
	name?: string;
	address?: string;
	phone?: string;
}
