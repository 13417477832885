import API from 'Api/Api';
import { CreateReservationParams, ReservationDetailsParams, ReservationFetchParams } from './ReservationTypes';

//get Reservation
export const takeReservations = (data: ReservationFetchParams) => {
	return API({
		url: '/reservations',
		method: 'GET',
		params: data
	});
};

//get Reservation Details
export const takeReservation = (data: ReservationDetailsParams) => {
	return API({
		url: '/reservations',
		method: 'GET',
		params: data
	});
};

//create Reservation Details
export const makeReservation = (data: CreateReservationParams) => {
	return API({
		url: '/reservations',
		method: 'POST',
		params: data
	});
};

//modify Reservation
export const modifyReservation = (data: CreateReservationParams) => {
	return API({
		url: `/reservations/${data?.id}`,
		method: 'PUT',
		data: data
	});
};

//delete Reservation
export const cutReservation = (data: ReservationDetailsParams) => {
	return API({
		url: '/reservations',
		method: 'DELETE',
		params: data
	});
};
