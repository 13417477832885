import { Col, Descriptions, Divider, Row, Tag, Typography } from 'antd';
import DataTable from 'Components/Table/DataTable';
import dayjs from 'dayjs';
import React from 'react';
import { TableColumn } from 'react-data-table-component';
import { TotalAmount } from 'Utils/TotalAmount';
import { ReactComponent as TableIcon } from 'Assets/table.svg';

export const EnquiryView = (props: any) => {
    const { data } = props;
    return (
        <div>
            {/* <Typography.Title disabled level={5}>RESERVATION DETAILS</Typography.Title>
            <Divider /> */}
            <Descriptions column={2} labelStyle={{ color: 'gray' }} contentStyle={{ fontWeight: 'bold', borderBottom: '1px solid #efefef', paddingBottom: 10 }} colon={false} layout="vertical" title="GENERAL DETAILS">
                <Descriptions.Item label="Name">{data?.fullName}</Descriptions.Item>
                {/* <Descriptions.Item label="Booking For">{dayjs(data?.reservationDate).format('LL')}</Descriptions.Item> */}
                {/* <Descriptions.Item label="Time"><Tag color='gray'>{dayjs(data?.startTime, 'HH:mm:ss').format('LT')} - {dayjs(data?.endTime, 'HH:mm:ss').format('LT')}</Tag></Descriptions.Item> */}
                {/* <Descriptions.Item label="Phone">{data?.contact}</Descriptions.Item> */}
                <Descriptions.Item label="Email">{data?.email}</Descriptions.Item>
                <Descriptions.Item contentStyle={{color: 'green', fontWeight: 'normal'}} span={2} label="Message">{data?.message}</Descriptions.Item>
                {/* <Descriptions.Item label="State">{data?.state}</Descriptions.Item> */}
                {/* <Descriptions.Item label="Zip Code">{data?.zipCode}</Descriptions.Item> */}
            </Descriptions>
            </div>
    );
};
