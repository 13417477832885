import { Button, Space, Tag } from "antd"
import React from "react"

export const NextOrderStep = (props: any) => {
    switch (props?.id) {
        case 4:
            return <Space><Button size="small" type="primary" onClick={()=> props?.onConfirm()}>CONFIRM ORDER</Button><Button onClick={()=> props?.onReject()} type="primary" size="small" danger>REJECT ORDER</Button></Space>
        case 1:
            return <Button  onClick={()=> props?.onDispatched()}size="small" style={{background: 'orange', borderColor: 'orange'}} type="primary">DISPATCH</Button>
        case 3:
            return <Button  onClick={()=> props?.onCompleted()} size="small" style={{background: 'green', borderColor: 'green'}} type="primary">COMPLETED</Button>

        default:
            // return <Tag color="blue">COMPLETED</Tag>
            return <Tag color="gray">N/A</Tag>
    }
}

export const NextReservationStep = (props: any) => {
    switch (props?.id) {
        case 15:
            return <Tag color="gray">N/A</Tag>
        case 12:
            return <Space><Button onClick={() => props?.onConfirm()} size="small" type="primary">CONFIRM</Button></Space>
        case 11:
            return <Space><Button onClick={()=> props?.onReject()} type="primary" size="small" danger>CANCEL</Button><Button onClick={()=> props?.onComplete()} type="primary" size="small">COMPLETED</Button></Space>
        case 14:
            return <Space><Button onClick={()=> props?.onConfirm()} size="small" type="primary">CONFIRM</Button><Button onClick={()=> props?.onReject()} type="primary" size="small" danger>REJECT</Button></Space>

        default:
            return <Tag color="blue">CONFIRMED</Tag>
    }
}