import {
  FETCH_USER_LIST,
  FETCH_USER_LIST_FAILURE,
  FETCH_USER_LIST_SUCCESS,
} from "Redux/Constants";
import { ListResponse, UserFetch } from "Types/Types";

export const fetchUserList = (data: UserFetch) => ({
  type: FETCH_USER_LIST,
  payload: { params: data },
});
export const fetchUserListSuccess = (response: ListResponse) => ({
  type: FETCH_USER_LIST_SUCCESS,
  payload: { ...response },
});

export const fetchUserListFailure = (error: string) => ({
  type: FETCH_USER_LIST_FAILURE,
  payload: { error: error },
});
