import React, { useState } from 'react';
import { Button, Divider, Dropdown, Layout, Space } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined, PlusOutlined } from '@ant-design/icons';
import Sidebar from './Sidebars/SideBar';
import PrimaryBreadcumb from 'Components/PrimaryBreadcumb/PrimaryBreadcumb';
import Form from 'Views/Forms/Forms';
import MyHeader from 'Components/MyHeader/MyHeader';
import MegaMenu from './MegaMenu';
import Animate from 'rc-animate';

const { Header, Sider, Content } = Layout;

export const ContentLayout = (props: any) => {
	const [ collapsed, setCollapsed ] = useState(false);
	return (
		<Animate animati>
			<Layout>
				<Form />
				<Sider
					theme="light"
					trigger={null}
					collapsible
					collapsed={collapsed}
					onCollapse={() => setCollapsed(!collapsed)}
				>
					<div className="logo" />
					<Sidebar />
				</Sider>
				<Layout className="site-layout">
					<Header className="site-layout-background" style={{ padding: 0, paddingLeft: 20 }}>
						<Space
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center'
							}}
						>
							<Space>
								{React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
									className: 'trigger',
									onClick: () => setCollapsed(!collapsed)
								})}
								{/* <Dropdown overlay={<MegaMenu />}>
									<Button type="primary">
										ADD <PlusOutlined />
									</Button>
								</Dropdown> */}
							</Space>

							<MyHeader />
						</Space>
					</Header>
					{/* <Header
						className="site-layout-background"
						style={{
							padding: 0,
							height: 45,
							lineHeight: '45px',
							paddingLeft: 20
						}}
					>
						<Divider style={{ margin: 0 }} />
						<Space>
							<PrimaryBreadcumb />
						</Space>
					</Header> */}
					<Content
						className="site-layout"
						style={{
							// margin: "24px 16px",
							padding: '10px 10px',
							minHeight: '98vh'
						}}
					>
						{props.children}
					</Content>
				</Layout>
			</Layout>
		</Animate>
	);
};

export default ContentLayout;
