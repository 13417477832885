import axios from 'axios';
export const baseUrl = 'https://api.filippopizzeria.com';
// export const baseUrl = 'https://api.nepalrugby.com';
// export const WebsiteUrl = 'https://rugbynepal.org';

const getToken = () => {
	let token: any = false;
	try {
		token = 'Bearer ' + JSON.parse(localStorage.getItem('auths') || '')?.jwt;
	} catch (error) {
		console.log(error);
		localStorage.setItem('loggedIn', 'false');
	}
	return token;
};


//apis definitions

export const API = axios.create({
	baseURL: `${baseUrl}/`
});

export const AuthApi = axios.create({
	baseURL: baseUrl
});

export const MediaApis = axios.create({
	baseURL: baseUrl
});


//api request interceptors

API.interceptors.request.use(
	async (config) => {
		config.headers = {
			Authorization: getToken(),
			'Content-Type': 'application/json'
		};

		return config;
	},
	(error) => {
		Promise.reject(error);
	}
);

//API for media upload
MediaApis.interceptors.request.use(
	async (config) => {
		config.headers = {
			Authorization: getToken()
		};
		return config;
	},
	(error) => {
		Promise.reject(error);
	}
);





//response interceptors

API.interceptors.response.use(
	(response: any) => {
		return response;
	},
	(error) => {
		if (error?.response?.status === 401) {
			sessionStorage.removeItem('auths');
			localStorage.removeItem('loggedIn');
			window.location.reload();
		}
		Promise.reject(error);
	}
)




export default API;
