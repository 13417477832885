import React from "react";
import { Route, RouteChildrenProps, Switch } from "react-router";
import DashboardRoute from "./DashboardRoute";
import EnquiriesRoute from "./EnquiriesRoute";
import OrderRoute from "./OrderRoute";
import ReservationRoute from "./ReservationRoute";
import { RouteGuard } from "./RouteGuard";

export const ProtectedRoute = (props: RouteChildrenProps) => {
  return (
    <RouteGuard>
      <Switch>
        <Route path={`${props.match?.path}dashboard`} component={DashboardRoute} />
        <Route path={`${props.match?.path}orders`} component={OrderRoute} />
        <Route path={`${props.match?.path}reservations`} component={ReservationRoute} />
        <Route path={`${props.match?.path}enquiries`} component={EnquiriesRoute} />
        {/* <Route path={`${props.match?.path}members`} component={MemberRoute} /> */}
        <Route component={DashboardRoute} />
      </Switch>
    </RouteGuard>
  );
};

export default ProtectedRoute;
