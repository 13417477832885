import { Reducer } from 'react';
import {
	CreateOrderParams,
	CREATE_ORDER,
	CREATE_ORDER_FAILURE,
	CREATE_ORDER_SUCCESS,
	FETCH_ORDERS,
	FETCH_ORDERS_FAILURE,
	FETCH_ORDERS_SUCCESS,
	FETCH_ORDER_DETAILS,
	FETCH_ORDER_DETAILS_FAILURE,
	FETCH_ORDER_DETAILS_SUCCESS,
	REMOVE_ORDER,
	REMOVE_ORDER_FAILURE,
	REMOVE_ORDER_SUCCESS,
	UPDATE_ORDER,
	UPDATE_ORDER_FAILURE,
	UPDATE_ORDER_SUCCESS,
	OrderDetailsParams
} from './OrderTypes';

const INIT_STATE = {
	list: [],
	error: '',
	detailsError: '',
	details: {},
	loading: false,
	loaded: false,
	detailing: false,
	detailed: false,
	creating: false,
	created: false,
	deleting: false,
	deleted: false,
	updating: false,
	updated: false,
	total: 0,
	page: 1
};

export const OrderReducer: Reducer<any, any> = (state = INIT_STATE, action) => {
	switch (action.type) {
		//fetch Orders
		case FETCH_ORDERS:
			return { ...state, loading: true };
		case FETCH_ORDERS_SUCCESS:
			return { ...state, loading: false, list: action.payload };
		case FETCH_ORDERS_FAILURE:
			return { ...state, loading: false, error: action.payload };

		//fetch Order Details
		case FETCH_ORDER_DETAILS:
			return { ...state, detailing: true };
		case FETCH_ORDER_DETAILS_SUCCESS:
			return { ...state, detailing: false, details: action.payload };
		case FETCH_ORDER_DETAILS_FAILURE:
			return { ...state, detailing: false, detailsError: action.payload };

		//create Order
		case CREATE_ORDER:
			return { ...state, creating: true };
		case CREATE_ORDER_SUCCESS:
			state.list.push(action.payload);
			return { ...state, creating: false };
		case CREATE_ORDER_FAILURE:
			return { ...state, creating: false };

		//update Order
		case UPDATE_ORDER:
			return { ...state, updating: true };
		case UPDATE_ORDER_SUCCESS:
			const tempList = state.list.filter((d: CreateOrderParams) => d.id != action.payload.id);
			tempList.push(action.payload);
			return { ...state, updating: false, list: tempList };
		case UPDATE_ORDER_FAILURE:
			return { ...state, updating: false };

		//remove Order
		case REMOVE_ORDER:
			return { ...state, deleting: true };
		case REMOVE_ORDER_SUCCESS:
			return {
				...state,
				deleting: false,
				list: state.list.filter((d: OrderDetailsParams) => d.id != action.payload.id)
			};
		case REMOVE_ORDER_FAILURE:
			return { ...state, deleting: false };

		default:
			return { ...state };
	}
};
