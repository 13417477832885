import { Reducer } from 'react';
import {
	CreateEnquiryParams,
	CREATE_ENQUIRY,
	CREATE_ENQUIRY_FAILURE,
	CREATE_ENQUIRY_SUCCESS,
	FETCH_ENQUIRYS,
	FETCH_ENQUIRYS_FAILURE,
	FETCH_ENQUIRYS_SUCCESS,
	FETCH_ENQUIRY_DETAILS,
	FETCH_ENQUIRY_DETAILS_FAILURE,
	FETCH_ENQUIRY_DETAILS_SUCCESS,
	REMOVE_ENQUIRY,
	REMOVE_ENQUIRY_FAILURE,
	REMOVE_ENQUIRY_SUCCESS,
	UPDATE_ENQUIRY,
	UPDATE_ENQUIRY_FAILURE,
	UPDATE_ENQUIRY_SUCCESS,
	EnquiryDetailsParams
} from './EnquiryTypes';

const INIT_STATE = {
	list: [],
	error: '',
	detailsError: '',
	details: {},
	loading: false,
	loaded: false,
	detailing: false,
	detailed: false,
	creating: false,
	created: false,
	deleting: false,
	deleted: false,
	updating: false,
	updated: false,
	total: 0,
	page: 1
};

export const EnquiryReducer: Reducer<any, any> = (state = INIT_STATE, action) => {
	switch (action.type) {
		//fetch Enquirys
		case FETCH_ENQUIRYS:
			return { ...state, loading: true };
		case FETCH_ENQUIRYS_SUCCESS:
			return { ...state, loading: false, list: action.payload };
		case FETCH_ENQUIRYS_FAILURE:
			return { ...state, loading: false, error: action.payload };

		//fetch Enquiry Details
		case FETCH_ENQUIRY_DETAILS:
			return { ...state, detailing: true };
		case FETCH_ENQUIRY_DETAILS_SUCCESS:
			return { ...state, detailing: false, details: action.payload };
		case FETCH_ENQUIRY_DETAILS_FAILURE:
			return { ...state, detailing: false, detailsError: action.payload };

		//create Enquiry
		case CREATE_ENQUIRY:
			return { ...state, creating: true };
		case CREATE_ENQUIRY_SUCCESS:
			state.list.push(action.payload);
			return { ...state, creating: false };
		case CREATE_ENQUIRY_FAILURE:
			return { ...state, creating: false };

		//update Enquiry
		case UPDATE_ENQUIRY:
			return { ...state, updating: true };
		case UPDATE_ENQUIRY_SUCCESS:
			const tempList = state.list.filter((d: CreateEnquiryParams) => d.id != action.payload.id);
			tempList.push(action.payload);
			return { ...state, updating: false, list: tempList };
		case UPDATE_ENQUIRY_FAILURE:
			return { ...state, updating: false };

		//remove Enquiry
		case REMOVE_ENQUIRY:
			return { ...state, deleting: true };
		case REMOVE_ENQUIRY_SUCCESS:
			return {
				...state,
				deleting: false,
				list: state.list.filter((d: EnquiryDetailsParams) => d.id != action.payload.id)
			};
		case REMOVE_ENQUIRY_FAILURE:
		return { ...state, deleting: false };
			default:
				return {...state}
	}
};
