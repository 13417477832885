import {
	CreateEnquiryParams,
	CREATE_ENQUIRY,
	CREATE_ENQUIRY_FAILURE,
	CREATE_ENQUIRY_SUCCESS,
	FETCH_ENQUIRYS,
	FETCH_ENQUIRYS_FAILURE,
	FETCH_ENQUIRYS_SUCCESS,
	FETCH_ENQUIRY_DETAILS,
	FETCH_ENQUIRY_DETAILS_FAILURE,
	FETCH_ENQUIRY_DETAILS_SUCCESS,
	REMOVE_ENQUIRY,
	REMOVE_ENQUIRY_FAILURE,
	REMOVE_ENQUIRY_SUCCESS,
	UPDATE_ENQUIRY,
	UPDATE_ENQUIRY_FAILURE,
	UPDATE_ENQUIRY_SUCCESS,
	EnquiryDetailsParams,
	EnquiryFetchParams
} from './EnquiryTypes';

//fetch Enquiry List
export const fetchEnquirys = (params: EnquiryFetchParams) => ({
	type: FETCH_ENQUIRYS,
	payload: params
});

export const fetchEnquirysSuccess = (response: any) => ({
	type: FETCH_ENQUIRYS_SUCCESS,
	payload: response
});

export const fetchEnquirysFailure = (error: any) => ({
	type: FETCH_ENQUIRYS_FAILURE,
	payload: error
});

//fetch Enquiry Details
export const fetchEnquiry = (params: EnquiryDetailsParams) => ({
	type: FETCH_ENQUIRY_DETAILS,
	payload: params
});

export const fetchEnquirySuccess = (response: any) => ({
	type: FETCH_ENQUIRY_DETAILS_SUCCESS,
	payload: response
});

export const fetchEnquiryFailure = (error: any) => ({
	type: FETCH_ENQUIRY_DETAILS_FAILURE,
	payload: error
});

//create Enquiry
export const createEnquiry = (params: CreateEnquiryParams) => ({
	type: CREATE_ENQUIRY,
	payload: params
});

export const createEnquirySuccess = (response: CreateEnquiryParams) => ({
	type: CREATE_ENQUIRY_SUCCESS,
	payload: response
});
export const createEnquiryFailure = (error: any) => ({
	type: CREATE_ENQUIRY_FAILURE,
	payload: error
});

//update Enquiry
export const updateEnquiry = (params: CreateEnquiryParams) => ({
	type: UPDATE_ENQUIRY,
	payload: params
});

export const updateEnquirySuccess = (response: CreateEnquiryParams) => ({
	type: UPDATE_ENQUIRY_SUCCESS,
	payload: response
});

export const updateEnquiryFailure = (error: any) => ({
	type: UPDATE_ENQUIRY_FAILURE,
	payload: error
});

//delete Enquiry
export const deleteEnquiry = (params: EnquiryDetailsParams) => ({
	type: REMOVE_ENQUIRY,
	payload: params
});
export const deleteEnquirySuccess = (response: EnquiryDetailsParams) => ({
	type: REMOVE_ENQUIRY_SUCCESS,
	payload: response
});
export const deleteEnquiryFailure = (error: any) => ({
	type: REMOVE_ENQUIRY_FAILURE,
	payload: error
});
