import API from 'Api/Api';
import { CreateOrderParams, OrderDetailsParams, OrderFetchParams } from './OrderTypes';

//get Order
export const takeOrders = (data: OrderFetchParams) => {
	return API({
		url: '/orders',
		method: 'GET',
		params: data
	});
};

//get Order Details
export const takeOrder = (data: OrderDetailsParams) => {
	return API({
		url: '/orders',
		method: 'GET',
		params: data
	});
};

//create Order Details
export const makeOrder = (data: CreateOrderParams) => {
	return API({
		url: '/orders',
		method: 'POST',
		params: data
	});
};

//modify Order
export const modifyOrder = (data: CreateOrderParams) => {
	return API({
		url: `/orders/${data?.id}`,
		method: 'PUT',
		data: data
	});
};

//delete Order
export const cutOrder = (data: OrderDetailsParams) => {
	return API({
		url: '/orders',
		method: 'DELETE',
		params: data
	});
};
