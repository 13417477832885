import DataTable from "Components/Table/DataTable";
import { TableHeader } from "Components/TableHeader/TableHeader";
import { FormEvent, useEffect, useState } from "react";
import { TableColumn } from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { setLayout } from "Redux/Actions/LayoutActions";
import React from "react"
import MyContent from "Components/Content/Content";
import { Tag, Typography } from "antd";
import dayjs from "dayjs";
import Status from "Components/Status/Status";
import { getReservationStatus, getStatusKey } from "Utils/OrderStatuses";
import { NextOrderStep, NextReservationStep } from "Components/NextStep/NextStep";
import DetailsView from "Components/DetailsView/DetailsView";
import { fetchEnquirys } from "Redux/EnquiryRedux/EnquiryActions";

export const EnquiriesList = (props: any) => {
	const exams = useSelector((state: any) => state.EnquiryReducer);

const [details, setDetails] = useState<any>(false);

	const [filterText, setFilterText] = React.useState('');
	const [perPageData, setPerPageData] = useState(10);
	const [filteredItems, setFilteredItems] = useState<any>([]);
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

	const dispatch = useDispatch();
	const handleClear = () => {
		if (filterText) {
			setResetPaginationToggle(!resetPaginationToggle);
			setFilterText('');
		}
	};

	//Fetch data with filter
	const fetchWithFilter = (data: any) => {
		// console.log(data)
		dispatch(fetchEnquirys(data))
	}
	//Row and Per page change
	const handlePerRowsChange = (perPage: number, page: number) => {
		fetchWithFilter({ page: page, limit: perPage });
		setPerPageData(perPage);
	};

	//on table page change
	const handlePageChange = (page: number) => {
		fetchWithFilter({ page: page, limit: perPageData });
	};

	//on row clicked
	const onRowClicked = (values: any) => {
		// setShowDetails(true);
		setDetails(values)
	}

	const onEditHandler = (data: any) => {
		dispatch(setLayout({ data: data, form: 'exam', update: true }));
	}

	const onDeleteHandler = (id: number) => {
		console.log(id)
		// dispatch(removeEmployee(id))
	}

	const userColumns: TableColumn[] = [
		{
			name: 'Name',
			selector: 'fullName',
			sortable: true
		},
		{
			name: 'Received At',
			selector: 'createdAt',
			sortable: true,
			cell: (row: any) => <Typography.Text style={{ textTransform: 'capitalize', padding: '10px 0px' }}>{dayjs(row?.createdAt).format('LLL')}</Typography.Text>
		},
		{
			name: 'Email',
			selector: 'email',
			cell: (row: any) => <Typography.Link href={`mailto:${row?.email}`}>{ row?.email}</Typography.Link>

		},
		{
			name: 'Phone',
			selector: 'contact',
			cell: (row: any) => <Typography.Link>{row?.contact}</Typography.Link>,

		},
		// {
		// 	name: 'Status',
		// 	cell: (row: any) => <Status id={getReservationStatus(row)} />,
		// 	width: '100px'
		// },
		// {
		// 	name: 'Action',
		// 	// cell: (row: any) => <TableActions onJoin={()=> setCurrentExam(row?.id)} onDetail={()=> history.push(`/exams/details/${row.id}`)} noDeletable onDelete={() => onDeleteHandler(row.id)} onEdit={() => onEditHandler(row)} />
		// 	cell: (row: any) => <NextReservationStep id={ getReservationStatus(row)}/>
		// }
	];


	useEffect(() => {
		const fItems = exams?.list?.filter(
			(item: any) => item.fullName && item.fullName.toLowerCase().includes(filterText.toLowerCase())
		);
		setFilteredItems(fItems);

	}, [filterText, exams])



	useEffect(() => {
		if (!exams.loaded) {
			fetchWithFilter({});
		}
	}, [])


	return (
		<>
			<TableHeader
				onFilter={(e: FormEvent<HTMLInputElement>) => setFilterText(e.currentTarget.value)}
				onClear={handleClear}
				filterText={filterText}
				module="Enquiries"
				title="Enquiry"
				noAddFeature
				// otherModule={[{ title: "Level", module: "exam-level" }]}
				// extraRight={<ExtraComponent />}
			/>
			<MyContent shadow={true}> 
				<DataTable
					columns={userColumns}
					data={filteredItems}
					progressPending={exams.loading}
					noHeader
					pagination
					onRowClicked={onRowClicked}
					paginationServer
					onChangeRowsPerPage={handlePerRowsChange}
					onChangePage={handlePageChange}
					paginationTotalRows={exams.total}
				/>
			</MyContent>

			<DetailsView module="enquiries" data={details} onHide={() => setDetails(false)} />

		</>
	);
};

export default EnquiriesList;
