import { Reducer } from 'react';
import {
	CreateReservationParams,
	CREATE_RESERVATION,
	CREATE_RESERVATION_FAILURE,
	CREATE_RESERVATION_SUCCESS,
	FETCH_RESERVATIONS,
	FETCH_RESERVATIONS_FAILURE,
	FETCH_RESERVATIONS_SUCCESS,
	FETCH_RESERVATION_DETAILS,
	FETCH_RESERVATION_DETAILS_FAILURE,
	FETCH_RESERVATION_DETAILS_SUCCESS,
	REMOVE_RESERVATION,
	REMOVE_RESERVATION_FAILURE,
	REMOVE_RESERVATION_SUCCESS,
	UPDATE_RESERVATION,
	UPDATE_RESERVATION_FAILURE,
	UPDATE_RESERVATION_SUCCESS,
	ReservationDetailsParams
} from './ReservationTypes';

const INIT_STATE = {
	list: [],
	error: '',
	detailsError: '',
	details: {},
	loading: false,
	loaded: false,
	detailing: false,
	detailed: false,
	creating: false,
	created: false,
	deleting: false,
	deleted: false,
	updating: false,
	updated: false,
	total: 0,
	page: 1
};

export const ReservationReducer: Reducer<any, any> = (state = INIT_STATE, action) => {
	switch (action.type) {
		//fetch Reservations
		case FETCH_RESERVATIONS:
			return { ...state, loading: true };
		case FETCH_RESERVATIONS_SUCCESS:
			return { ...state, loading: false, list: action.payload };
		case FETCH_RESERVATIONS_FAILURE:
			return { ...state, loading: false, error: action.payload };

		//fetch Reservation Details
		case FETCH_RESERVATION_DETAILS:
			return { ...state, detailing: true };
		case FETCH_RESERVATION_DETAILS_SUCCESS:
			return { ...state, detailing: false, details: action.payload };
		case FETCH_RESERVATION_DETAILS_FAILURE:
			return { ...state, detailing: false, detailsError: action.payload };

		//create Reservation
		case CREATE_RESERVATION:
			return { ...state, creating: true };
		case CREATE_RESERVATION_SUCCESS:
			state.list.push(action.payload);
			return { ...state, creating: false };
		case CREATE_RESERVATION_FAILURE:
			return { ...state, creating: false };

		//update Reservation
		case UPDATE_RESERVATION:
			return { ...state, updating: true };
		case UPDATE_RESERVATION_SUCCESS:
			const tempList = state.list.filter((d: CreateReservationParams) => d.id != action.payload.id);
			tempList.push(action.payload);
			return { ...state, updating: false, list: tempList };
		case UPDATE_RESERVATION_FAILURE:
			return { ...state, updating: false };

		//remove Reservation
		case REMOVE_RESERVATION:
			return { ...state, deleting: true };
		case REMOVE_RESERVATION_SUCCESS:
			return {
				...state,
				deleting: false,
				list: state.list.filter((d: ReservationDetailsParams) => d.id != action.payload.id)
			};
		case REMOVE_RESERVATION_FAILURE:
		return { ...state, deleting: false };
			default:
				return {...state}
	}
};
