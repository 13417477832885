import { notification } from 'antd';
import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
	createReservationFailure,
	createReservationSuccess,
	deleteReservationFailure,
	deleteReservationSuccess,
	fetchReservationFailure,
	fetchReservationsFailure,
	fetchReservationsSuccess,
	fetchReservationSuccess,
	updateReservationFailure,
	updateReservationSuccess
} from './ReservationActions';
import {
	cutReservation,
	makeReservation,
	modifyReservation,
	takeReservation,
	takeReservations
} from './ReservationApis';
import {
	CreateReservationParams,
	CREATE_RESERVATION,
	REMOVE_RESERVATION,
	FETCH_RESERVATIONS,
	FETCH_RESERVATION_DETAILS,
	UPDATE_RESERVATION,
	ReservationDetailsParams,
	ReservationFetchParams
} from './ReservationTypes';

//get reservations
const getReservationsAsync = async (data: ReservationFetchParams) => await takeReservations(data);

function* getReservations(params: any) {
	const { payload } = params;
	try {
		const reservations: AxiosResponse = yield call(getReservationsAsync, payload);
		if (reservations) {
			yield put(fetchReservationsSuccess(reservations.data));
		} else {
			yield put(fetchReservationsFailure('Could not get list of reservations'));
			notification.error({
				message: 'Get Reservation List',
				description: 'Could not get list of reservations'
			});
		}
	} catch (error) {
		yield put(fetchReservationsFailure('Network Error while getting Reservations'));
		notification.error({
			message: 'Get Reservation List',
			description: 'Network Error while getting Reservations'
		});
	}
}

function* watchGetReservations() {
	yield takeLatest(FETCH_RESERVATIONS, getReservations);
}

//get reservation details
const getReservationAsync = async (data: ReservationDetailsParams) => await takeReservation(data);

function* getReservation(params: any) {
	const { payload } = params;
	try {
		const reservations: AxiosResponse = yield call(getReservationAsync, payload);
		if (reservations) {
			yield put(fetchReservationSuccess(reservations.data));
		} else {
			yield put(fetchReservationFailure('Could not get list of reservations'));
			notification.error({
				message: 'Get Reservation',
				description: 'Could not get list of reservations'
			});
		}
	} catch (error) {
		yield put(fetchReservationFailure('Network Error while getting Reservations'));
		notification.error({
			message: 'Get Reservation',
			description: 'Network Error while getting Reservations'
		});
	}
}

function* watchGetReservation() {
	yield takeLatest(FETCH_RESERVATION_DETAILS, getReservation);
}

//create Reservation
const generateReservationAsync = async (data: CreateReservationParams) => await makeReservation(data);

function* generateReservation(params: any) {
	const { payload } = params;
	try {
		const reservations: AxiosResponse = yield call(generateReservationAsync, payload);
		if (reservations) {
			yield put(createReservationSuccess(reservations.data));
			notification.success({
				message: 'Create Reservation',
				description: 'Reservation created successfully'
			});
		} else {
			const message = 'Could not create Reservation';
			yield put(createReservationFailure(message));
			notification.error({
				message: 'Create Reservation',
				description: message
			});
		}
	} catch (error) {
		const message = 'Network Error while creating Reservation';
		yield put(createReservationFailure(message));
		notification.error({
			message: 'Create Reservation',
			description: message
		});
	}
}

function* watchGenerateReservation() {
	yield takeLatest(CREATE_RESERVATION, generateReservation);
}

//update Reservation
const amendReservationAsync = async (data: CreateReservationParams) => await modifyReservation(data);

function* amendReservation(params: any) {
	const { payload } = params;
	try {
		const reservations: AxiosResponse = yield call(amendReservationAsync, payload);
		if (reservations) {
			yield put(updateReservationSuccess(reservations.data));
			notification.success({
				message: 'Update Reservation',
				description: 'Reservation updated successfully'
			});
		} else {
			const message = 'Could not Update Reservation';
			yield put(updateReservationFailure(message));
			notification.error({
				message: 'Update Reservation',
				description: message
			});
		}
	} catch (error) {
		const message = 'Network Error while updating Reservation';
		yield put(updateReservationFailure(message));
		notification.error({
			message: 'Update Reservation',
			description: message
		});
	}
}

function* watchAmendReservation() {
	yield takeLatest(UPDATE_RESERVATION, amendReservation);
}

//Delete Reservation
const removeReservationAsync = async (data: ReservationDetailsParams) => await cutReservation(data);

function* removeReservation(params: any) {
	const { payload } = params;
	try {
		const reservations: AxiosResponse = yield call(removeReservationAsync, payload);
		if (reservations) {
			yield put(deleteReservationSuccess(reservations.data));
			notification.success({
				message: 'Reservation Deleted',
				description: 'Reservation deleted successfully'
			});
		} else {
			const message = 'Could not delete Reservation';
			yield put(deleteReservationFailure(message));
			notification.error({
				message: 'Reservation Deleted',
				description: message
			});
		}
	} catch (error) {
		const message = 'Network Error while deleting Reservation';
		yield put(deleteReservationFailure(message));
		notification.error({
			message: 'Reservation Deleted',
			description: message
		});
	}
}

function* watchRemoveReservation() {
	yield takeLatest(REMOVE_RESERVATION, removeReservation);
}

export default function* ReservationSagas() {
	yield all([
		fork(watchGetReservations),
		fork(watchGetReservation),
		fork(watchGenerateReservation),
		fork(watchAmendReservation),
		fork(watchRemoveReservation)
	]);
}
