import { takeUserList } from "Api/UserApis";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  fetchUserListFailure,
  fetchUserListSuccess,
} from "Redux/Actions/UserActions";
import { FETCH_USER_LIST } from "Redux/Constants";
import { UserFetch } from "Types/Types";
const getUserListAsync = (data: UserFetch) => takeUserList(data);

function* getUserList(payload: any) {
  const { params } = payload.payload;
  console.log(params);
  try {
    const userList = yield call(getUserListAsync, params);
    if (userList) {
      yield put(fetchUserListSuccess(userList.data));
    } else {
      yield put(fetchUserListFailure("Something went wrong"));
    }
  } catch (error) {
    yield put(fetchUserListFailure("Something went wrong"));
  }
}

function* watchGetUserList() {
  yield takeEvery(FETCH_USER_LIST, getUserList);
}

export default function* UserSagas() {
  yield all([fork(watchGetUserList)]);
}
