import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import UserReducers from './UserReducers';
import LayoutReducers from './LayoutReducers';
import AuthReducers from './AuthReducer';
import { OrderReducer } from 'Redux/OrderRedux/OrderReducer';
import { ReservationReducer } from 'Redux/ReservationRedux/ReservationReducer';
import { EnquiryReducer } from 'Redux/EnquiryRedux/EnquiryReducer';
export const rootReducers = (history: any) =>
	combineReducers({
		router: connectRouter(history),
		UserReducer: UserReducers,
		LayoutReducer: LayoutReducers,
		AuthReducer: AuthReducers,
		OrderReducer: OrderReducer,
		ReservationReducer: ReservationReducer,
		EnquiryReducer: EnquiryReducer
	});

export type RootState = ReturnType<typeof rootReducers>;
