import {
	CreateOrderParams,
	CREATE_ORDER,
	CREATE_ORDER_FAILURE,
	CREATE_ORDER_SUCCESS,
	FETCH_ORDERS,
	FETCH_ORDERS_FAILURE,
	FETCH_ORDERS_SUCCESS,
	FETCH_ORDER_DETAILS,
	FETCH_ORDER_DETAILS_FAILURE,
	FETCH_ORDER_DETAILS_SUCCESS,
	REMOVE_ORDER,
	REMOVE_ORDER_FAILURE,
	REMOVE_ORDER_SUCCESS,
	UPDATE_ORDER,
	UPDATE_ORDER_FAILURE,
	UPDATE_ORDER_SUCCESS,
	OrderDetailsParams,
	OrderFetchParams
} from './OrderTypes';

//fetch Order List
export const fetchOrders = (params: OrderFetchParams) => ({
	type: FETCH_ORDERS,
	payload: params
});

export const fetchOrdersSuccess = (response: any) => ({
	type: FETCH_ORDERS_SUCCESS,
	payload: response
});

export const fetchOrdersFailure = (error: any) => ({
	type: FETCH_ORDERS_FAILURE,
	payload: error
});

//fetch Order Details
export const fetchOrder = (params: OrderDetailsParams) => ({
	type: FETCH_ORDER_DETAILS,
	payload: params
});

export const fetchOrderSuccess = (response: any) => ({
	type: FETCH_ORDER_DETAILS_SUCCESS,
	payload: response
});

export const fetchOrderFailure = (error: any) => ({
	type: FETCH_ORDER_DETAILS_FAILURE,
	payload: error
});

//create Order
export const createOrder = (params: CreateOrderParams) => ({
	type: CREATE_ORDER,
	payload: params
});

export const createOrderSuccess = (response: CreateOrderParams) => ({
	type: CREATE_ORDER_SUCCESS,
	payload: response
});
export const createOrderFailure = (error: any) => ({
	type: CREATE_ORDER_FAILURE,
	payload: error
});

//update Order
export const updateOrder = (params: CreateOrderParams) => ({
	type: UPDATE_ORDER,
	payload: params
});

export const updateOrderSuccess = (response: CreateOrderParams) => ({
	type: UPDATE_ORDER_SUCCESS,
	payload: response
});

export const updateOrderFailure = (error: any) => ({
	type: UPDATE_ORDER_FAILURE,
	payload: error
});

//delete Order
export const deleteOrder = (params: OrderDetailsParams) => ({
	type: REMOVE_ORDER,
	payload: params
});
export const deleteOrderSuccess = (response: OrderDetailsParams) => ({
	type: REMOVE_ORDER_SUCCESS,
	payload: response
});
export const deleteOrderFailure = (error: any) => ({
	type: REMOVE_ORDER_FAILURE,
	payload: error
});
