import { Route, Switch } from 'react-router';
import Reservation from 'Views/Reservation/Reservation';
export const ReservationRoute = (props: any) => {
  // const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(fetchMembers({ _limit: 10 }))
  // }, [])
  return (
    <Switch>
      <Route path={`${props.match?.path}/`} component={Reservation} />
      {/* <Route component={Pages}/> */}
    </Switch>
  )
}

export default ReservationRoute;