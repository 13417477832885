import { Col, Row } from 'antd';
import React from 'react';
import ReservationList from './ReservationList';

export const Reservation = (props: any) => {
	return (
		<Row gutter={[ 30, 30 ]} style={{ marginTop: 0 }} justify="center">
			<Col lg={22}>
				<ReservationList />
			</Col>
		</Row>
	);
};

export default Reservation;
