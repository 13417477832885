export const FETCH_RESERVATIONS = 'FETCH_RESERVATIONS';
export const FETCH_RESERVATIONS_SUCCESS = 'FETCH_RESERVATIONS_SUCCESS';
export const FETCH_RESERVATIONS_FAILURE = 'FETCH_RESERVATIONS_FAILURE';

export const FETCH_RESERVATION_DETAILS = 'FETCH_RESERVATION_DETAILS';
export const FETCH_RESERVATION_DETAILS_SUCCESS = 'FETCH_RESERVATION_DETAILS_SUCCESS';
export const FETCH_RESERVATION_DETAILS_FAILURE = 'FETCH_RESERVATION_DETAILS_FAILURE';

export const CREATE_RESERVATION = 'CREATE_RESERVATION';
export const CREATE_RESERVATION_SUCCESS = 'CREATE_RESERVATION_SUCCESS';
export const CREATE_RESERVATION_FAILURE = 'CREATE_RESERVATION_FAILURE';

export const UPDATE_RESERVATION = 'UPDATE_RESERVATION';
export const UPDATE_RESERVATION_SUCCESS = 'UPDATE_RESERVATION_SUCCESS';
export const UPDATE_RESERVATION_FAILURE = 'UPDATE_RESERVATION_FAILURE';

export const REMOVE_RESERVATION = 'REMOVE_RESERVATION';
export const REMOVE_RESERVATION_SUCCESS = 'REMOVE_RESERVATION_SUCCESS';
export const REMOVE_RESERVATION_FAILURE = 'REMOVE_RESERVATION_FAILURE';

export interface ReservationFetchParams {
	limit?: number;
	page?: number;
	_sort?: string;
}
export interface ReservationDetailsParams {
	id?: number;
}
export interface CreateReservationParams {
	id?: number;
	name?: string;
	address?: string;
	phone?: string;
	isConfirmed?: boolean;
	isRejected?: boolean;
	isCompleted?: boolean;
}
