import { Reducer } from 'react';
import { SET_AUTH } from 'Redux/Constants';

const INIT_STATE = {
	loggedIn: JSON.parse(localStorage.getItem('loggedIn') || 'false') || false,
	error: '',
	requesting: false,
	loginRequested: false
};

export const AuthReducers: Reducer<any, any> = (state = INIT_STATE, action: any) => {
	switch (action.type) {
		case SET_AUTH:
			return {
				...state,
				...action.payload.params
			};
		default:
			return { ...state };
	}
};

export default AuthReducers;
