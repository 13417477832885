import { Col, Descriptions, Divider, Row, Typography } from 'antd';
import DataTable from 'Components/Table/DataTable';
import React from 'react';
import { TableColumn } from 'react-data-table-component';
import { TotalAmount } from 'Utils/TotalAmount';

export const OrderDetails = (props: any) => {
    const { data } = props;
    TotalAmount(data)
    const OrderColumn: TableColumn[] = [
        {
            name: 'Item Name',
            selector: 'item',
            cell: (row: any) => <Typography.Link>{row?.item?.name}</Typography.Link>

        },
        {
            name: 'Quantity',
            selector: 'quantity'
        },
        {
            name: 'Rate',
            selector: 'tAmount'
        },
        {
            name: 'Total',
            cell: (row: any) => <Typography.Text>{row?.total || (row?.quantity * row?.tAmount)}</Typography.Text>
        }
    ]

    const total = TotalAmount(data);

    const lastcolumn = {
        tAmount: 'TOTAL',
        total: total
    }

    const conditionalRowStyles = [
        {
            when: (row: any) => row.total > 0,
            style: {
                backgroundColor: 'rgba(63, 195, 128, 0.9)',
                color: 'white',
                '&:hover': {
                    cursor: 'pointer',
                }
            },
        }
    ];
    return (
        <div>
            <Typography.Title disabled level={5}>SHIPPING DETAILS</Typography.Title>
            <Divider />
            <Descriptions column={2} labelStyle={{ color: 'gray' }} contentStyle={{ fontWeight: 'bold', borderBottom: '1px solid #efefef', paddingBottom: 10 }} colon={false} layout="vertical" title="GENERAL DETAILS">
                <Descriptions.Item label="Name">{data?.name}</Descriptions.Item>
                <Descriptions.Item label="Address">{data?.address}</Descriptions.Item>
                <Descriptions.Item label="Alt. Address">{data?.altAddress}</Descriptions.Item>
                <Descriptions.Item label="Phone">{data?.phone}</Descriptions.Item>
                <Descriptions.Item label="Email">{data?.email}</Descriptions.Item>
                <Descriptions.Item label="State">{data?.state}</Descriptions.Item>
                <Descriptions.Item label="Zip Code">{data?.zipCode}</Descriptions.Item>
            </Descriptions>
            <Typography.Title disabled level={5}>ORDER SUMMARY</Typography.Title>
            <Divider />
            <DataTable conditionalRowStyles={conditionalRowStyles} noHeader title={false} data={[...data?.items, lastcolumn]} columns={OrderColumn} />
        </div>
    );
};
