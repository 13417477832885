import { Row, Spin } from 'antd';

export const Loading = (props: any) => {
	return (
		<Row justify="center" style={{ margin: '40px 0px' }}>
			<Spin tip="Loading Data" />
		</Row>
	);
};

export default Loading;
